<template>
  <h3 class="subtitle">Check Number</h3>
  <div class="table-responsive">
    <table class="table mb-3">
      <tbody>
        <tr>
          <td></td>
          <td>Location</td>
          <td>Date</td>
        </tr>
        <tr v-for="location in uploadResponse.Locations" :key="location.Type">
          <td>{{ location.Type }}</td>
          <td>{{ location.Location }}</td>
          <td>{{ formatDate(location.Date) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    uploadResponse() {
      return this.$store.state.upload.response;
    },
  },
  methods: {
    formatDate(value) {
      return new Date(value).toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    },
  },
};
</script>
<style lang="scss"></style>
