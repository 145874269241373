<template>
  <div class="card mb-4">
    <h3 class="subtitle">Your Information</h3>
    <form action="#" @submit.prevent="postFileUpload">
      <div class="lg:flex gap-3">
        <div class="lg:w-6/12">
          <div
            class="form-group"
            :class="{ error: v$.Yform.LoadID.$errors.length }"
          >
            <label>Load# <span class="required-star">*</span></label>
            <input
              type="text"
              class="form-control"
              v-model="v$.Yform.LoadID.$model"
              disabled
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.Yform.LoadID.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </div>
        <div class="lg:w-6/12">
          <div class="form-group">
            <label>MC#/USDOT# <span class="required-star">*</span></label>
            <input
              type="text"
              class="form-control"
              :value="DocMcNumber"
              disabled
            />
          </div>
        </div>
      </div>
      <div class="lg:flex gap-3">
        <div class="lg:w-6/12">
          <div
            class="form-group"
            :class="{ error: v$.Yform.FirstName.$errors.length }"
          >
            <label>Name<span class="required-star">*</span></label>
            <input
              type="text"
              class="form-control"
              v-model="v$.Yform.FirstName.$model"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.Yform.FirstName.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </div>
        <div class="lg:w-6/12">
          <div
            class="form-group"
            :class="{ error: v$.Yform.PhoneNumber.$errors.length }"
          >
            <label>Phone<span class="required-star">*</span></label>
            <input
              type="text"
              class="form-control"
              @input="acceptPhoneNumber()"
              v-model="v$.Yform.PhoneNumber.$model"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.Yform.PhoneNumber.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="lg:flex gap-3">
        <div class="lg:w-6/12">
          <div
            class="form-group"
            :class="{ error: v$.Yform.EmailAddress.$errors.length }"
          >
            <label>Email<span class="required-star">*</span></label>
            <input
              type="text"
              class="form-control"
              v-model="v$.Yform.EmailAddress.$model"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.Yform.EmailAddress.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </div>
      </div>
      <p><strong>Payment Type</strong></p>
      <div class="lg:flex">
        <div class="flex items-center mr-2">
          <input
            type="radio"
            class="mr-1"
            name="paymentstatus"
            value="Standard"
            v-model="Yform.picked"
            @change="updatePaymentStatus"
          />
          <label>Standard 30 Day Payment</label>
        </div>
        <div class="flex items-center mr-2">
          <input
            type="radio"
            class="mr-1"
            name="paymentstatus"
            value="OneDay"
            v-model="Yform.picked"
            @change="updatePaymentStatus"
          />
          <label>One Day Quickpay (5% + $15 fee)</label>
        </div>
        <div class="flex items-center mr-2">
          <input
            type="radio"
            name="paymentstatus"
            value="FiveDay"
            v-model="Yform.picked"
            class="mr-1"
            @change="updatePaymentStatus"
          />
          <label>Five Day Quickpay (3% + $15 fee)</label>
        </div>
      </div>
    </form>
  </div>
  <div class="flex items-center mb-4">
    <input type="checkbox" class="mr-1" v-model="send" />
    <label>Generate an Invoice</label>
  </div>
  <RemitToInformation
    class="mb-4"
    @InvoiceData="InvoiceData"
    v-if="send"
  ></RemitToInformation>
  <div class="card">
    <h3 class="subtitle">Documents</h3>
    {{ status }}
    <div class="lg:flex gap-3">
      <div class="lg:w-6/12">
        <div class="form-group">
          <input id="file1" type="file" @change="convertBase641" />
        </div>
      </div>
      <div class="lg:w-6/12">
        <div class="form-group">
          <select
            name=""
            ref="selectedfiletype1"
            class="custom-select"
            v-model="form.filetype1"
            @change="convertBase641"
          >
            <option value="Invoice">My Invoice</option>
            <option value="BOL">Bill of landing</option>
            <option value="POD">Proof of delivery</option>
            <option value="RateCon">Rate Confirmation</option>
            <option value="LoadPacket">Complete load packet</option>
            <option value="Other">Other</option>
          </select>
        </div>
      </div>
    </div>
    <div class="lg:flex gap-3">
      <div class="lg:w-6/12">
        <div class="form-group">
          <input id="file2" type="file" @change="convertBase642" />
        </div>
      </div>
      <div class="lg:w-6/12">
        <div class="form-group">
          <select
            name=""
            ref="selectedfiletype2"
            class="custom-select"
            v-model="form.filetype2"
            @change="convertBase642"
          >
            <option value="Invoice">My Invoice</option>
            <option value="BOL">Bill of landing</option>
            <option value="POD">Proof of delivery</option>
            <option value="RateCon">Rate Confirmation</option>
            <option value="LoadPacket">Complete load packet</option>
            <option value="Other">Other</option>
          </select>
        </div>
      </div>
    </div>
    <div class="lg:flex gap-3">
      <div class="lg:w-6/12">
        <div class="form-group">
          <input id="file3" type="file" @change="convertBase643" />
        </div>
      </div>
      <div class="lg:w-6/12">
        <div class="form-group">
          <select
            name=""
            ref="selectedfiletype3"
            class="custom-select"
            v-model="form.filetype3"
            @change="convertBase643"
          >
            <option value="Invoice">My Invoice</option>
            <option value="BOL">Bill of landing</option>
            <option value="POD">Proof of delivery</option>
            <option value="RateCon">Rate Confirmation</option>
            <option value="LoadPacket">Complete load packet</option>
            <option value="Other">Other</option>
          </select>
        </div>
      </div>
    </div>

    <button class="main-btn" @click="postFileUpload">Submit Documents</button>
    <div v-if="fileError" class="fileerror">
      {{ fileErrorMsg }}
    </div>
    <div class="success-msg" v-if="IsSuccessful">{{ responseText }}</div>
    <div class="pending-msg" v-if="pendingmsg && !fileError">
      <div>
        <svg
          class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="#000"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="#ccc"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="#ccc"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        Processing...
      </div>
    </div>
  </div>
  <div class="pdf" v-show="dontshow">
    <div ref="content">
      <div class="content" v-if="IData.Carrier">
        <h3 class="text-center invoice-title">Invoice</h3>
        <div class="lg:flex gap-3 mb-4 content">
          <div class="lg:w-6/12">
            <strong>Ldi load id:{{ IData.LoadID }}</strong>
            <p>
              <strong>Invoice #:{{ IData.InvoiceNumber }}</strong>
            </p>
          </div>
          <div class="lg:w-6/12 text-right">
            Date: {{ formatDate(IData.InvoiceDate) }}
          </div>
        </div>
        <div class="mb-4 content">
          <p>{{ IData.Carrier.Name }}</p>
          <p>{{ IData.Carrier.AddressLine1 }}</p>
          <p>{{ IData.Carrier.City }}</p>
          <p>{{ IData.Carrier.State }},{{ IData.Carrier.PostalCode }}</p>
          <p>{{ IData.Carrier.Phone }}</p>
          <p>{{ IData.Carrier.Email }}</p>
        </div>
        <div class="lg:flex gap-3 mb-4 content cm">
          <div class="lg:w-6/12">
            <strong>Customer</strong>
            <p>{{ IData.BillTo.Name }}</p>
            <p>{{ IData.BillTo.AddressLine1 }}</p>
            <p>{{ IData.BillTo.City }}</p>
            <p>{{ IData.BillTo.State }},{{ IData.BillTo.PostalCode }}</p>
            <p>{{ IData.BillTo.Phone }}</p>
            <p>{{ IData.BillTo.Email }}</p>
          </div>

          <div class="lg:w-6/12">
            <strong>Payable to</strong>
            <p>{{ IData.RemitTo.Name }}</p>
            <p>{{ IData.RemitTo.AddressLine1 }}</p>
            <p>{{ IData.RemitTo.City }}</p>
            <p>{{ IData.RemitTo.State }},{{ IData.RemitTo.PostalCode }}</p>
            <p>{{ IData.RemitTo.Phone }}</p>
            <p>{{ IData.RemitTo.Email }}</p>
          </div>
        </div>
        <table class="table">
          <tbody>
            <tr>
              <td>Description</td>
              <td style="width: 150px">Amount</td>
            </tr>
            <tr v-for="item in IData.LineItems" :key="item.Description">
              <td>{{ item.Description }}</td>
              <td>{{ item.Amount }}</td>
            </tr>
            <tr>
              <td>Total</td>
              <td>{{ IData.TotalAmount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers, email, minLength } from "@vuelidate/validators";
import { dataProvider } from "@/api/dataProvider";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import RemitToInformation from "@/components/common/RemitToInformation.vue";
export default {
  props: ["status", "DocMcNumber"],
  data() {
    return {
      IData: [],
      v$: useVuelidate(),
      Yform: {
        FirstName: this.$store.state.login.FirstName,
        PhoneNumber: this.$store.state.login.PhoneNumber,
        EmailAddress: this.$store.state.login.EmailAddress,
        MCNumber: this.DocMcNumber,
        LoadID: this.$store.state.upload.LoadID,
        picked: "Standard",
      },
      form: {
        Documents: [],
        filetype1: "Invoice",
        filetype2: "Invoice",
        filetype3: "Invoice",
      },
      IsSuccessful: false,
      responseText: "",
      file: "",
      file1: "",
      file1base64: "",
      pendingmsg: false,
      form1: "",
      url: "",
      invoicevalid: false,
      podvalid: false,
      fileError: false,
      fileErrorMsg: "",
      send: false,
      submitStatus: false,
    };
  },
  validations() {
    return {
      Yform: {
        FirstName: {
          required: helpers.withMessage("Name is required", required),
        },
        EmailAddress: {
          required: helpers.withMessage("Email address is required", required),
          email: helpers.withMessage("Please enter a valid email", email),
        },
        LoadID: {
          required: helpers.withMessage("LoadId is required", required),
        },
        PhoneNumber: {
          required: helpers.withMessage("Phone Number is required", required),
          minLength: helpers.withMessage(
            "Please enter a valid phone number",
            minLength(14)
          ),
        },
      },
    };
  },
  components: {
    RemitToInformation,
  },
  methods: {
    acceptPhoneNumber() {
      var x = this.Yform.PhoneNumber.replace(/\D/g, "").match(
        /(\d{0,3})(\d{0,3})(\d{0,4})/
      );
      this.Yform.PhoneNumber = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    async postFileUpload() {
      this.v$.Yform.$touch();
      if (this.v$.Yform.$error) return;
      var fileEncode = "";
      this.pendingmsg = true;
      const html = this.$refs.content.innerHTML;
      window.html2canvas = html2canvas;
      var doc = new jsPDF("p", "pt", "a4");
      await doc.html(html, {
        callback: (doc) => {
          var out = doc.output();
          fileEncode = btoa(out);
        },
        width: 500,
        windowWidth: 500,
        x: 50,
        y: 20,
      });

      this.InvoicBase64StringObj = Object.assign(
        { InvoicBase64String: fileEncode },
        this.form
      );
      this.LoadIDObj = Object.assign(
        { LoadID: this.Yform.LoadID },
        this.InvoicBase64StringObj
      );
      this.PaymentTypeObj = Object.assign(
        { PaymentType: this.Yform.picked },
        this.LoadIDObj
      );
      this.ContactNameObj = Object.assign(
        { ContactName: this.Yform.FirstName },
        this.PaymentTypeObj
      );

      this.ContactEmailObj = Object.assign(
        { ContactEmail: this.Yform.EmailAddress },
        this.ContactNameObj
      );
      this.ContactPhoneObj = Object.assign(
        { ContactPhone: this.Yform.PhoneNumber },
        this.ContactEmailObj
      );

      this.fileUploadObj = Object.assign(
        { SendInvoice: this.submitStatus },
        this.ContactPhoneObj
      );

      for (var i = 0; i < this.fileUploadObj.Documents.length; i++) {
        if (this.fileUploadObj.Documents[i].FileName == "Invoice") {
          this.invoicevalid = true;
        }
        if (this.fileUploadObj.Documents[i].FileName == "POD") {
          this.podvalid = true;
        }
      }

      if (this.invoicevalid == true && this.podvalid == true) {
        var uploadDetails = await dataProvider.postUploadDetails(
          this.fileUploadObj
        );
        this.IsSuccessful = uploadDetails.IsSuccessful;
        this.responseText = uploadDetails.ResponseText;
        this.pendingmsg = false;
        setTimeout(() => (this.IsSuccessful = false), 3000);
      } else {
        this.fileError = true;
        this.fileErrorMsg = "Invoice and POD is required";
        setTimeout(
          () => ((this.fileError = false), (this.pendingmsg = false)),
          3000
        );
        return;
      }
    },

    convertBase641() {
      var files = document.getElementById("file1").files;
      var selectedfiletype = this.form.filetype1;

      if (files.length > 0) {
        var extension = files[0].name.split(".")[1];
        this.getBase641(files[0], this.form, extension, selectedfiletype);
      }
    },
    convertBase642() {
      var files = document.getElementById("file2").files;
      var selectedfiletype = this.form.filetype2;

      if (files.length > 0) {
        var extension = files[0].name.split(".")[1];
        this.getBase642(files[0], this.form, extension, selectedfiletype);
      }
    },
    convertBase643() {
      var files = document.getElementById("file3").files;
      var selectedfiletype = this.form.filetype3;

      if (files.length > 0) {
        var extension = files[0].name.split(".")[1];
        this.getBase643(files[0], this.form, extension, selectedfiletype);
      }
    },
    getBase641(file, form, extension, selectedfiletype) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        if (reader.result !== null) {
          form.Documents.splice(0, 1, {
            Data: reader.result,
            FileType: extension,
            FileName: selectedfiletype,
          });
        }
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
      return reader.onload();
    },
    getBase642(file, form, extension, selectedfiletype) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        if (reader.result !== null) {
          form.Documents.splice(1, 1, {
            Data: reader.result,
            FileType: extension,
            FileName: selectedfiletype,
          });
        }
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
      return reader.onload();
    },
    getBase643(file, form, extension, selectedfiletype) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        if (reader.result !== null) {
          form.Documents.splice(2, 1, {
            Data: reader.result,
            FileType: extension,
            FileName: selectedfiletype,
          });
        }
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
      return reader.onload();
    },
    formatDate(value) {
      return new Date(value).toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    },
    InvoiceData(iData, flag) {
      this.IData = iData;
      this.submitStatus = flag;
    },
  },

  watch: {
    uploadResponse() {
      return this.$store.state.upload.LoadID;
    },
  },
};
</script>
<style lang="scss">
.success-msg {
  background: #5ad568ee;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
  border-radius: 6px;
  margin-top: 10px;
}
.pending-msg {
  background: #fafafa;
  color: #181818;
  padding: 10px 20px;
  text-align: center;
  border-radius: 6px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.form-group {
  margin-bottom: 20px;
  &.error {
    .form-control {
      border: 1px solid #ff073a;
    }
  }
}
.form-control {
  margin-bottom: 5px;
}
.input-errors {
  font-size: 14px;
  color: #ff073a;
}
.fileerror {
  background: #ec7343ee;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
  border-radius: 6px;
  margin-top: 10px;
}

.pdf {
  .invoice-title {
    font-size: 24px;
    color: #181818;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  table {
    width: 100%;
  }
  table,
  td,
  th {
    font-size: 11px;
    border: 0.5px solid #6f6f6f;
    border-collapse: collapse;
  }
  td {
    padding: 6px;
  }
  .content {
    font-size: 11px;
  }
}
.cm {
  margin-bottom: 20px;
}
</style>
