<template>
  <div v-if="uploadResponse">
    <div v-if="uploadResponse.IsSuccessful">
      <div
        class="lg:grid grid-cols-5 gap-3 mb-4"
        v-if="uploadResponse.PaymentInfo.length > 0"
      >
        <div class="lg:w-2/10">
          <div class="widget">
            <h4 class="widget-title">LDI Load#</h4>
            <div class="count">{{ uploadResponse.PaymentInfo[0].LoadID }}</div>
          </div>
        </div>
        <div class="lg:w-2/10">
          <div class="widget">
            <h4 class="widget-title">Payment Status</h4>
            <div class="count">
              {{ uploadResponse.PaymentInfo[0].PaymentStatus }}
            </div>
          </div>
        </div>
        <div class="lg:w-2/10">
          <div class="widget">
            <h4 class="widget-title">Check Number</h4>
            <div class="count">
              {{ uploadResponse.PaymentInfo[0].CheckNumber }}
            </div>
          </div>
        </div>
        <div class="lg:w-2/10">
          <div class="widget">
            <h4 class="widget-title">Payment Date</h4>
            <div class="count">
              {{ formatDate(uploadResponse.PaymentInfo[0].PaymentDate) }}
            </div>
          </div>
        </div>
        <div class="lg:w-2/10">
          <div class="widget">
            <h4 class="widget-title">Payment Amount</h4>
            <div class="count">
              {{ uploadResponse.PaymentInfo[0].Amount }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    uploadResponse() {
      return this.$store.state.upload.response;
    },
  },
  methods: {
    formatDate(value) {
      return new Date(value).toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    },
  },
};
</script>
<style lang="scss">
.widget {
  padding: 20px;
  background: #fff;
  border-radius: 6px;
  text-align: center;
  height: 100%;
  .widget-title {
    font-size: 16px;
    font-weight: 700;
    color: #757575;
    margin-bottom: 10px;
  }
  .count {
    font-weight: 700;
    font-size: 27px;
    color: #181818;
  }
}
</style>
